import { Constants } from "@/constants/constants";
import { Logger } from "@/helpers/Logger";
import { useSettingsStore } from "@/stores/useSettingsStore";
import * as Sentry from "@sentry/vue";
import { formatISO, isValid } from "date-fns";
import { storeToRefs } from "pinia";
import { Priority } from "./Priority";
import { Status } from "./Status";
import { Syncable } from "./Syncable";
import { Urgency } from "./Urgency";
import { UrgencyUtils } from "./UrgencyUtils";
import { safeFormatISO } from "@/helpers/DateHelper";

export interface TaskType extends Syncable {
  id?: string;
  title?: string;
  completed?: Date;
  isStarred?: boolean;
  due?: Date;
  showDueTime?: boolean;
  repeat?: string;
  start?: Date;
  parentId?: string;
  folderId?: string;
  contextId?: string;
  goalId?: string;
  priority?: Priority;
  status?: Status;
  added?: Date;
  modified?: Date;
  note?: string;
  urgency?: Urgency;
  imported?: Date;

  toSimpleObject(): any;
}

export class TaskObject implements TaskType {
  id?: string;
  title?: string;
  completed?: Date;
  isStarred?: boolean;
  due?: Date;
  showDueTime?: boolean;
  repeat?: string;
  start?: Date;
  parentId?: string;
  folderId?: string;
  contextId?: string;
  goalId?: string;
  priority?: Priority;
  status?: Status;
  added?: Date;
  modified?: Date;
  note?: string;
  type: "Task";
  // isSelected?: boolean;
  imported?: Date;

  constructor(taskData: Partial<TaskObject>) {
    Object.assign(this, taskData);
  }

  // constructor(title?: string) {
  //   this.title = title ?? '';
  // }

  public get urgency() {
    const settingsStore = useSettingsStore();
    const { autoUrgency } = storeToRefs(settingsStore);
    return UrgencyUtils.urgencyFromPriorityWithAutoUrgency(
      this.priority,
      this.due,
      this.completed,
      autoUrgency.value
    );
  }

  public set urgency(urgency: Urgency) {
    this.priority = UrgencyUtils.urgencyToPriority(urgency);
  }

  public getUrgency(autoUrgency: boolean): Urgency {
    return UrgencyUtils.urgencyFromPriorityWithAutoUrgency(
      this.priority,
      this.due,
      this.completed,
      autoUrgency
    );
  }

  toSimpleObject() {
    const defaults = {
      id: "",
      title: "",
      completed: "",
      isStarred: false,
      due: "",
      showDueTime: false,
      repeat: Constants.REPEAT_NONE,
      start: "",
      parentId: "",
      folderId: "",
      contextId: "",
      goalId: "",
      priority: Priority.Low,
      status: Status.None,
      added: "",
      modified: "",
      note: "",
      imported: "",
    };

    const result = {
      ...defaults,
      ...this,
    };

    return result;
  }

  public static autoUrgency = false;

  public calcUrgency(withAutoUrgency: boolean) {
    return UrgencyUtils.urgencyFromPriorityWithAutoUrgency(
      this.priority,
      this.due,
      this.completed,
      withAutoUrgency
    );
  }

  toFirestoreObject() {
    try {
      console.log(
        `task toFirestoreObject starts with task: ${JSON.stringify(this)}`
      );
      const result = {
        id: this.id ?? "",
        title: this.title ?? "",
        completed: safeFormatISO(this.completed),
        isStarred: this.isStarred ?? false,
        due: safeFormatISO(this.due),
        showDueTime: this.showDueTime ?? false,
        repeat: this.repeat ?? "",
        start: safeFormatISO(this.start),
        parentId: this.parentId ?? "",
        folderId: this.folderId ?? "",
        contextId: this.contextId ?? "",
        goalId: this.goalId ?? "",
        priority: this.priority ?? "",
        status: "",
        added: safeFormatISO(this.added),
        modified: safeFormatISO(this.modified),
        imported: safeFormatISO(this.imported),
        note: this.note ?? "",
      };
      console.log(
        `task toFirestoreObject returns task: ${JSON.stringify(result)}`
      );
      return result;
    } catch (error) {
      console.error("task toFirestoreObject catches an error", error);
      throw error;
    }
  }

  static fromFirestoreObject(source: any, id?: string): TaskObject {
    try {
      if (!source) {
        return null;
      }

      return new TaskObject({
        ...source,
        id: id ? id : source.id,
        due: source.due ? new Date(source.due) : undefined,
        start: source.start ? new Date(source.start) : undefined,
        added: source.added ? new Date(source.added) : undefined,
        priority: Priority[source.priority] ?? Priority.Low,
      });
    } catch (error) {
      Logger.logError("Error in TaskObject.fromAnyObject", error);
      throw error;
    }
  }
}
