import { formatISO, isValid } from "date-fns";
import { Syncable } from "./Syncable";
import { Logger } from "@/helpers/Logger";
import { safeFormatISO } from "@/helpers/DateHelper";

export interface NoteType extends Syncable {
  id?: string;
  title?: string;
  folderId?: string;
  added?: Date;
  modified?: Date;
  text?: string;

  copyToSimpleObject(): any;
}

export class NoteObject implements NoteType {
  id?: string;
  title?: string;
  folderId?: string;
  added?: Date;
  modified?: Date;
  text?: string;

  constructor(noteData: Partial<NoteObject>) {
    Object.assign(this, noteData);
  }

  copyToSimpleObject() {
    const defaults = {
      id: "",
      title: "",
      folderId: "",
      added: "",
      modified: "",
      text: "",
    };

    const result = {
      ...defaults,
      ...this,
    };

    return result;
  }

  copyToFirestoreObject() {
    const result = {
      id: this.id ?? "",
      title: this.title ?? "",
      folderId: this.folderId ?? "",

      added: safeFormatISO(this.added),
      modified: safeFormatISO(this.modified),

      text: this.text ?? "",
    };

    return result;
  }

  static fromFirestoreObject(source: any, withId?: string): NoteObject {
    const result: NoteObject = new NoteObject({
      ...source,
      id: withId ?? source.id,
      title: source.title ?? "",
      text: source.text ?? "",
      added: source.added ? new Date(source.added) : undefined,
      modified: source.modified ? new Date(source.modified) : undefined,
    });

    return result;
  }
}
