// set up the model

import { formatISO, isValid } from "date-fns";
import { Syncable } from "./Syncable";
import { safeFormatISO } from "@/helpers/DateHelper";

export interface ContextType extends Syncable {
  id?: string;
  title?: string;
  added?: Date;
  modified?: Date;

  copyToSimpleObject(): any;
}

export class ContextObject implements ContextType {
  id?: string;
  title?: string;
  added?: Date;
  modified?: Date;

  constructor(contextData: Partial<ContextObject>) {
    Object.assign(this, contextData);
  }

  copyToSimpleObject() {
    const defaults = {
      id: "",
      title: "",
      added: "",
      modified: "",
    };

    const result = {
      ...defaults,
      ...this,
    };

    return result;
  }

  copyToFirestoreObject() {
    const result = {
      id: this.id ?? "",
      title: this.title ?? "",
      added: safeFormatISO(this.added),
      modified: safeFormatISO(this.modified),
    };
    return result;
  }

  static fromFirestoreObject(source: any): ContextObject {
    const c = new ContextObject({
      title: source.title ?? "",
      added: source.added ? new Date(source.added) : undefined,
      modified: source.modified ? new Date(source.modified) : undefined,
    });

    return c;
  }
}
