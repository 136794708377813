import { formatISO, isValid, startOfDay } from "date-fns";
import { Logger } from "./Logger";

export function safeFormatISO(date: Date): string | null {
  if (!isValid(date)) return null;
  console.log(`safeFormatISO sees date is ${date}`);
  return date ? formatISO(date) : null;
}

export class DateHelper {
  static convertDateToISO = (source: any): string | undefined => {
    if (!source) {
      return undefined;
    }
    try {
      const date = new Date(source);
      return formatISO(date);
    } catch (error) {
      Logger.logError(
        "DateHelper convertDateToISO encountered an error",
        error
      );
      throw error;
    }
  };

  static copyDate = (source: any): Date | undefined => {
    if (!source) {
      return undefined;
    }
    try {
      return new Date(source);
    } catch (error) {
      Logger.logError(
        `DateHelper: copyDate could not parse date from: ${source}`,
        error
      );
      throw error;
    }
  };

  static copyDateFromTimestamp = (source: any): Date | undefined => {
    const tsec = source.seconds;
    if (tsec) {
      const result = new Date(tsec);
      return result;
    }
    return undefined;
  };

  static get now() {
    return new Date();
  }

  static dateOnly(date: Date): Date {
    const result = startOfDay(date);
    return result;
  }

  static get today(): Date {
    return this.dateOnly(this.now);
  }
}
