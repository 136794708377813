import { Logger } from "@/helpers/Logger";
import { FirebaseApp, initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import {
  getAuth,
  browserLocalPersistence,
  browserPopupRedirectResolver,
  browserSessionPersistence,
  indexedDBLocalPersistence,
  initializeAuth,
  User,
  connectAuthEmulator,
} from "firebase/auth";
import {
  connectFirestoreEmulator,
  Firestore,
  getFirestore,
  initializeFirestore,
} from "firebase/firestore";
import {
  persistentLocalCache,
  persistentSingleTabManager,
} from "firebase/firestore";
import { FirebaseStorage, getStorage } from "firebase/storage";

// const firebaseConfig = {
//   apiKey: "AIzaSyBu3DmnGDjJGUtbghHT2kVYkQVBCWb4koA",
//   authDomain: "taskangel-f1161.firebaseapp.com",
//   databaseURL: "https://taskangel-f1161.firebaseio.com",
//   projectId: "taskangel-f1161",
//   storageBucket: "taskangel-f1161.appspot.com", // This is the correct value
//   messagingSenderId: "541410762395",
//   appId: "1:541410762395:web:1129c4382cd9589167303e",
//   measurementId: "G-S1VDL12M4V",
// };
//
const firebaseConfig = {
  apiKey: "AIzaSyANtl6WWylJqCOm7dgMBClQue4D2fP9Yno",
  authDomain: "taskangel-f1161.firebaseapp.com",
  databaseURL: "https://taskangel-f1161.firebaseio.com",
  projectId: "taskangel-f1161",
  storageBucket: "taskangel-f1161.appspot.com",
  messagingSenderId: "541410762395",
  appId: "1:541410762395:web:1129c4382cd9589167303e",
  measurementId: "G-S1VDL12M4V",
};

export const app = initializeApp(firebaseConfig);

export let db: Firestore;
try {
  // db = initializeFirestore(app, {
  //   localCache: persistentLocalCache({
  //     tabManager: persistentSingleTabManager({}),
  //   }),
  // });
  db = getFirestore(app);
  const functions = getFunctions(app);
  const auth = getAuth(app);
  const useEmulators = process.env.NODE_ENV === "development";
  if (useEmulators) {
    connectFirestoreEmulator(db, "localhost", 8081);
    connectFunctionsEmulator(functions, "localhost", 5001);
    connectAuthEmulator(auth, "http://localhost:9099");
  }
} catch (error) {
  Logger.logError(
    `Error while initialising firestore. Please close any other versions of TaskAngel already running`,
    error
  );
  throw error;
}

export const storage: FirebaseStorage = getStorage(app);
