import { Constants } from "@/constants/constants";
import { auth, db, storage } from "@/firebase/firebase-auth";
import { updateProfile } from "firebase/auth";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";

import { Logger } from "@/helpers/Logger";
import { MyNotification } from "@/models/Notification";
import { useSettingsStore } from "@/stores/useSettingsStore";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  User,
  UserCredential,
} from "firebase/auth";
import { doc } from "firebase/firestore";
import { ResultAsync } from "neverthrow";
import { defineStore, storeToRefs } from "pinia";
import { useContextsStore } from "./useContextsStore";
import { useFeatureFlagsStore } from "./useFeatureFlagsStore";
import { useFoldersStore } from "./useFoldersStore";
import { useGoalsStore } from "./useGoalsStore";
import { useNotesStore } from "./useNotesStore";
import { useNotificationsStore } from "./useNotifications";
import { useSubscriptionsStore } from "./useSubscriptionsStore";
import { useTasksStore } from "./useTasksStore";
import { addDays, isAfter } from "date-fns";

function signOut(): ResultAsync<void, Error> {
  return ResultAsync.fromPromise(auth.signOut(), (originalError: any) => ({
    originalError,
    message: originalError.message,
    name: originalError.code,
  }));
}

export const useUserStore = defineStore("userStore", {
  state: () => ({
    // name: "",
    // email: '',
    // photoURL: "",
    hasProfileLoaded: false,
    hasLoadedUserData: false,
    isLoadingUserData: false,
    userLoggedOutToUnregister: false,
    authChangeUnsubscribe: null,
    signUpStep: Constants.SIGNUP_NONE,
    signUpUser: "",
    signUpPassword: "",
    _user: null as User | null,
    deferredInstallationPrompt: null, // set by App.vue for the 'Install' button to use.
  }),
  getters: {
    user: (state): User => {
      console.log(
        `useUserStore getter is returning user ${JSON.stringify(state._user)}`
      );
      return state._user;
    },
    name: (state) => {
      return state._user.displayName;
    },
    isLoggedIn(state): boolean {
      return !!state._user?.uid;
    },
    uid(state): string {
      return state._user?.uid;
    },

    userDocRef: (state) => {
      return doc(db, "users", state._user.uid);
    },

    email: (state): string => state._user?.email,
    photoURL: (state): string => state._user?.photoURL,

    userDataLoadStatus: () => {
      const foldersStore = useFoldersStore();
      if (!foldersStore.haveFoldersLoaded) {
        // if (foldersStore.isLoadingFolders) {
        return "Loading folders...";
        // }
      }

      const goalsStore = useGoalsStore();
      if (!goalsStore.haveGoalsLoaded) {
        // if (goalsStore.isLoadingGoals) {
        return "Loading goals...";
        // }
      }

      const contextsStore = useContextsStore();
      if (!contextsStore.haveContextsLoaded) {
        // if (contextsStore.isLoadingContexts) {
        return "Loading contexts...";
        // }
      }

      const tasksStore = useTasksStore();
      if (!tasksStore.haveTasksLoaded) {
        // if (tasksStore.isLoadingTasks) {
        return "Loading tasks...";
        // }
      }

      const notesStore = useNotesStore();
      if (!notesStore.haveNotesLoaded) {
        // if (notesStore.isLoadingNotes) {
        return "Loading notes...";
        // }
      }

      return "";
    },

    isEntitled() {
      try {
        if (!this.user) {
          return false;
        }

        const featureFlagsStore = useFeatureFlagsStore();
        const { freePassFlag } = storeToRefs(featureFlagsStore);
        if (freePassFlag.value) {
          return true;
        }

        if (this.isFounder) {
          return true;
        }

        const creationTime = this.user.metadata?.creationTime;
        if (!creationTime) {
          return true;
        }
        const whenUserCreated = new Date(creationTime);

        const now = new Date();

        const freeTrialExpiryDate = addDays(whenUserCreated, 7);
        if (freeTrialExpiryDate > now) {
          return true;
        }

        const subscriptionsStore = useSubscriptionsStore();
        if (subscriptionsStore.haveSubscriptionsLoaded)
          return subscriptionsStore.isSubscribing;
        const tempResult = true;
        return tempResult;
      } catch (error) {
        Logger.logError("Error when checking if isEnabled", error);
        throw error;
      }
    },

    isInFreeTrial() {
      const creationTime = this.user.metadata?.creationTime;
      if (!creationTime) {
        throw new Error(
          "Error: user has no creation time so unable to check free trial expiry"
        );
      }

      if (!this.freeTrialExpiryDate) return false;

      const now = new Date();
      const expiryDate: Date = this.freeTrialExpiryDate;
      if (!expiryDate) return true;
      return isAfter(expiryDate, now);
    },

    freeTrialExpiryDate(): Date | undefined {
      const creationTime = this.user.metadata?.creationTime;
      if (!creationTime) return undefined;
      const whenUserCreated = new Date(creationTime);
      return addDays(whenUserCreated, 7);
    },

    isFounder() {
      const creationTime = this.user.metadata?.creationTime;
      if (!creationTime) {
        throw new Error(
          "Error: user has no creation time so unable to check founder status"
        );
      }
      if (creationTime) {
        const whenUserCreated = new Date(creationTime);
        const lastFounderOfficialDate = new Date(2021, 4, 1);
        if (whenUserCreated && whenUserCreated < lastFounderOfficialDate) {
          return true;
        }
      }
      return false;
    },

    isSubscriber() {
      const subscriptionsStore = useSubscriptionsStore();
      const { haveSubscriptionsLoaded, isSubscribing } =
        storeToRefs(subscriptionsStore);

      return !haveSubscriptionsLoaded.value || isSubscribing.value;
    },

    tasksLimit() {
      if (this.isFounder || this.isSubscriber)
        return Constants.MAX_TASKS_WHEN_ACTIVE;
      return Constants.MAX_TASKS_IN_TRIAL;
    },
  },

  actions: {
    async setUser(newValue: User) {
      if (newValue && newValue?.uid === this._user?.uid) return;
      if (this._user) this.unloadUserData();
      this._user = newValue;
      this.$patch({
        hasLoadedUserData: false,
        isLoadingUserData: false,
      });
      // if (this._user) await this.loadUserData();
    },

    async saveUserProfile(name: string, photoURL?: string) {
      try {
        if (!this.user.uid) {
          Logger.logError("User must be logged in, to save her profile");
          return;
        }
        await updateProfile(this._user, {
          displayName: name,
          photoURL: photoURL,
        });
      } catch (error) {
        Logger.logError("Error saving user profile", error);
        throw error;
      }
    },

    signUpStep1Done(username: string) {
      this.signUpUser = username;
    },
    signUpStep2Done(password: string) {
      this.signUpPassword = password;
    },

    async signUpStep3Done(confirmPassword: string) {
      if (confirmPassword != this.signUpPassword) {
        throw Error(`Your passwords do not match`);
      }
      return await this.register(this.signUpUser, this.signUpPassword);
    },

    async register(username: string, password: string) {
      const credential: UserCredential = await createUserWithEmailAndPassword(
        auth,
        username,
        password
      );
      this._user = credential.user;
      return this.user;
    },
    async savePhoto(photo: File) {
      try {
        // const storage = getStorage(firebaseApp);
        Logger.log(`savePhoto starts`);
        const oldUrl = this.user.photoURL;
        if (oldUrl) {
          try {
            Logger.log(`savePhoto sees old photo and deletes it`);
            const oldRef = ref(storage, oldUrl);
            if (oldRef) {
              await deleteObject(oldRef);
            }
          } catch (error: any) {
            if (error.code === "storage/object-not-found") {
              Logger.log(
                `savePhoto sees old photo doesn't exist, so no need to delete it`
              );
            } else {
              Logger.logError(`Unable to delete old user photo`, error);
              throw error;
            }
          }
        }

        const path = `${this.user.uid}/profilePicture/${photo.name}`;
        Logger.log(`savePhoto sees photo path is ${path}`);

        const storageRef = ref(storage, path);

        Logger.log(`savePhoto starts uploading photo to Firebase storage`);

        await uploadBytes(storageRef, photo);
        const url: string = await getDownloadURL(storageRef);
        await updateProfile(this._user, {
          photoURL: url,
        });
      } catch (error) {
        Logger.logError(`Error in savePhoto`, error);
        throw error;
      }
    },

    unloadUserData() {
      useTasksStore().unsubscribeTasks();
      useTasksStore().clearTasks();

      useNotesStore().unsubscribeNotes();
      useNotesStore().clearNotes();

      useFoldersStore().unsubscribeFolders();
      useFoldersStore().clearFolders();

      useGoalsStore().unsubscribeGoals();
      useGoalsStore().clearGoals();

      useContextsStore().unsubscribeContexts();
      useContextsStore().clearContexts();

      useSettingsStore().clearSettings();

      useSubscriptionsStore().unsubscribeSubscriptions();
      useSubscriptionsStore().clearSubscriptions();

      this.hasLoadedUserData = false;
    },

    async loadUserData() {
      console.log("loadUserData is starting");

      if (this.hasLoadedUserData) {
        throw new Error(
          `Error: loadUserData sees user data has already loaded`
        );
      }
      try {
        console.log("loadUserData calls loadFolders");
        await useFoldersStore().loadFolders();
        console.log("loadUserData calls loadGoals");
        await useGoalsStore().loadGoals();
        console.log("loadUserData calls loadContexts");
        await useContextsStore().loadContexts();
        console.log("loadUserData calls loadTasks");
        await useTasksStore().loadTasks();
        console.log("loadUserData calls loadNotes");
        await useNotesStore().loadNotes();
        console.log("loadUserData calls loadSubscriptions");
        useSubscriptionsStore().loadSubscriptions();
        this.$patch({
          hasLoadedUserData: true,
          // isLoadingUserData: false,
        });
      } catch (error) {
        Logger.logError("Error loading user data", error);
        throw error;
      }
    },

    async refreshUserData() {
      try {
        await this.loadUserData();
      } catch (error: any) {
        Logger.logError("DError refreshing user data", error);
        throw error;
      }
    },

    async login(username: string, password: string) {
      try {
        const credential = await signInWithEmailAndPassword(
          auth,
          username,
          password
        );
        return credential.user;
      } catch (error) {
        Logger.logError("Error in login", error);
        throw error;
      }
    },

    async ifttt_login(username: string, password: string) {
      try {
        const userStore = useUserStore(); // get another instance so we can call other actions
        userStore.unloadUserData();

        const credentials = await signInWithEmailAndPassword(
          auth,
          username,
          password
        );
        const user = credentials.user;

        return await user?.getIdToken();
      } catch (error) {
        Logger.logError("Error logging into ifttt", error);
        throw error;
      }
    },
    async logout() {
      const result = await signOut();
      if (result.isOk()) {
        const userStore = useUserStore();

        userStore.unloadUserData();

        if (userStore.authChangeUnsubscribe) {
          userStore.authChangeUnsubscribe();
          userStore.authChangeUnsubscribe = null;
        }
      }
      return result;
    },

    async deleteUser() {
      const notifications = useNotificationsStore();
      try {
        if (!this.user) {
          Logger.logError("deleteUser has no user");
          return;
        }
        await this.user.delete();
        await auth.signOut();
        this._user = null;
      } catch (error: any) {
        const message =
          "TaskAngel needs you to approve de-registration by logging in again. Please log in and return to Settings page to try again";
        Logger.logError(message, error);
        notifications.addNotification(
          new MyNotification("De-register", message)
        );
      }
    },
  },
});
